import { ref, watch, type Ref } from "vue";

/** Persist the value of a ref to localstorage */
export function persistentRef<T>(key: string, initialValue: T): Ref<T> {
  if (typeof window === "undefined") {
    return ref<T>(initialValue) as Ref<T>;
  }
  key = "persistentRef:" + key;
  const value = localStorage.getItem(key);
  const parsedValue = value ? tryParse<T>(value) : initialValue;

  const r = ref<T>(parsedValue === null ? initialValue : parsedValue);
  watch(r, (v) => {
    if (v === null || v === undefined) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(v));
    }
  });
  return r as Ref<T>;
}

function tryParse<T>(value: string): T | null {
  try {
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
}
