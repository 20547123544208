import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import type { Supplier } from "@/types/supplier";
import type { SupplierRFQ } from "@/types/supplierRfq";
import type { ReactiveSet } from "@/utils/reactiveSet";
import { storeToRefs } from "pinia";
import { computed, type ComputedRef, inject, provide } from "vue";

const selectedGroupIdsSymbol = Symbol("selectedGroupIds");

type SupplierWithRfqId = Supplier & { rfqId: number };

interface SupplierRfqObjects {
  addSupplierRfq: (supplier: Supplier) => Promise<void>;
  removeSupplierRfq: (supplier: Supplier) => Promise<void>;
  selectedSuppliers: ComputedRef<{
    suppliersInAllSelectedGroups: SupplierWithRfqId[];
    suppliersInSomeSelectedGroups: SupplierWithRfqId[];
  }>;
  alreadySentSupplierRfqs: ComputedRef<SupplierRFQ[]>;
}

export const provideSupplierRfq = (selectedGroupIds: ReactiveSet<number>) => {
  const store = useCurrentOfferPositionGroupsStore();
  const { visibleOfferPositionGroups } = storeToRefs(store);

  const relevantGroups = computed(() => {
    return visibleOfferPositionGroups.value?.filter((group) =>
      selectedGroupIds.has(group.id)
    );
  });
  const relevantGroupIds = computed(() => {
    return relevantGroups.value?.map((group) => group.id) ?? [];
  });

  const selectedSuppliers = computed(() => {
    if (!relevantGroups.value)
      return {
        suppliersInAllSelectedGroups: [],
        suppliersInSomeSelectedGroups: [],
      };

    const rfqsAndCountById = new Map<
      number,
      { rfq: SupplierRFQ; count: number }
    >();

    relevantGroups.value.forEach((group) => {
      group.supplierRfqsDraft.forEach((rfq) => {
        const existing = rfqsAndCountById.get(rfq.id);
        if (existing) {
          existing.count++;
        } else {
          rfqsAndCountById.set(rfq.id, { rfq, count: 1 });
        }
      });
    });

    const suppliersInAllSelectedGroups = Array.from(rfqsAndCountById.values())
      .filter(({ count }) => count === selectedGroupIds.size)
      .map(({ rfq }) => ({ ...rfq.supplier, rfqId: rfq.id }));

    const suppliersInSomeSelectedGroups = Array.from(rfqsAndCountById.values())
      .filter(({ count }) => count < selectedGroupIds.size)
      .map(({ rfq }) => ({ ...rfq.supplier, rfqId: rfq.id }));

    return {
      suppliersInAllSelectedGroups,
      suppliersInSomeSelectedGroups,
    };
  });

  const alreadySentSupplierRfqs = computed(() => {
    if (!relevantGroups.value) return [] as SupplierRFQ[];

    const rfqsWithDuplicates = relevantGroups.value.flatMap(
      (group) => group.supplierRfqsNotDraft
    );

    return rfqsWithDuplicates.filter(
      (rfq, index, self) => index === self.findIndex((t) => t.id === rfq.id)
    );
  });

  type SupplierWithMaybeRfqId = Supplier & { rfqId?: number };

  async function addSupplierRfq(supplier: SupplierWithMaybeRfqId) {
    const rfq = await store.addSupplierRfq(supplier, relevantGroupIds.value);
    supplier.rfqId = rfq.id;
  }

  async function removeSupplierRfq(supplier: SupplierWithMaybeRfqId) {
    supplier.rfqId = undefined;
    await store.removeSupplierRfq(supplier, relevantGroupIds.value);
  }

  const supplierRfqObjects = {
    addSupplierRfq,
    removeSupplierRfq,
    selectedSuppliers,
    alreadySentSupplierRfqs,
  };

  provide<SupplierRfqObjects>(selectedGroupIdsSymbol, supplierRfqObjects);
};

export const useSupplierRfq = () => {
  const supplierRfqObjects = inject(selectedGroupIdsSymbol);
  if (!supplierRfqObjects) {
    throw new Error("useSupplierRfq must be used within a provideSupplierRfq");
  }
  return supplierRfqObjects as SupplierRfqObjects;
};
