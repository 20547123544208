<template>
  <div class="row no-wrap gap-sm">
    <div>
      <q-btn dense :disable="disable || !supplierRfq" text-color="neutral-7">
        <q-icon name="sym_r_draft" size="20px" class="q-mr-xs" />
        <q-icon name="sym_r_arrow_drop_down" size="20px" />
        <q-menu>
          <q-list dense>
            <q-item
              clickable
              v-ripple
              v-close-popup
              :active="supplierRfq && !supplierRfq.attachOriginalBoq"
              @click="emit('update:attachOriginalBoq', false)"
            >
              <q-item-section>
                <q-item-label>
                  <q-icon name="sym_r_list_alt" size="sm" class="q-mr-sm" />
                  <span>{{
                    $t("supplierRfqsPage.attachments.attachExcerpts")
                  }}</span>
                </q-item-label>
              </q-item-section>
            </q-item>
            <q-item
              clickable
              v-ripple
              v-close-popup
              :active="supplierRfq && supplierRfq.attachOriginalBoq"
              @click="emit('update:attachOriginalBoq', true)"
            >
              <q-item-section>
                <q-item-label>
                  <q-icon name="sym_r_content_copy" size="sm" class="q-mr-sm" />
                  <span>{{
                    $t("supplierRfqsPage.attachments.attachOriginalBoq")
                  }}</span>
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </div>
    <div class="row gap-sm items-center">
      <q-chip
        v-for="attachment in supplierRfq?.attachments"
        :key="attachment.id"
        color="neutral-3"
        class="q-ma-none attachment-chip"
        icon="sym_r_attach_file"
      >
        <div class="attachment-chip-content">
          <span v-if="!supplierRfq?.attachOriginalBoq">
            ({{ $t("supplierRfqsPage.attachments.excerpt") }})&nbsp;
          </span>
          <span>
            {{ attachment.filename }}
          </span>
        </div>
      </q-chip>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SupplierRFQ } from "@/types/supplierRfq";

defineProps<{
  supplierRfq?: SupplierRFQ;
  disable?: boolean;
}>();

const emit = defineEmits(["update:attachOriginalBoq"]);
</script>

<style scoped lang="scss">
.attachment-chip {
  .attachment-chip-content {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
