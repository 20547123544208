<template>
  <div class="row full-width items-center no-wrap lead-page-document-tabs">
    <q-tabs
      class="col full-width text-neutral-8"
      v-model="activeId"
      align="left"
      switch-indicator
      dense
      inline-label
      no-caps
    >
      <document-tab
        v-if="showEmailTab && email"
        id="email"
        :icon="icons.email"
        :filename="email.subject"
      />
      <document-tab
        v-for="document in documents"
        :key="document.id"
        :id="document.id"
        :icon="getDocumentTypeIcon(document.fileType)"
        :filename="document.filename"
      />
    </q-tabs>
  </div>
</template>

<script setup lang="ts">
import icons from "@/config/icons";
import type { Document } from "@/types/document";
import type { Email } from "@/types/email";
import { getDocumentTypeIcon } from "@/utils/icons";
import { computed } from "vue";
import DocumentTab from "./DocumentTab.vue";

const props = defineProps<{
  activeDocumentId: number | null;
  isEmailActive: boolean;
  email?: Email | null;
  showEmailTab: boolean;
  documents: Document[];
}>();

const emit = defineEmits<{
  "update:activeDocumentId": [id: number | null];
  "update:isEmailActive": [isEmailActive: boolean];
}>();

const activeId = computed({
  get: () => {
    if (props.isEmailActive) {
      return "email";
    }
    return props.activeDocumentId;
  },
  set: (value) => {
    if (value === "email") {
      emit("update:isEmailActive", true);
      emit("update:activeDocumentId", null);
    } else {
      emit("update:isEmailActive", false);
      emit("update:activeDocumentId", value);
    }
  },
});
</script>

<style scoped lang="scss">
.lead-page-document-tabs {
  border-top: 1px solid $separator-color;
}
</style>
