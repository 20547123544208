import { shallowRef, type ShallowRef, triggerRef } from "vue";

export class ReactiveSet<T> {
  private _ref: ShallowRef<Set<T>>;

  constructor(initialValues: T[] = []) {
    this._ref = shallowRef(new Set(initialValues));
  }

  add(value: T) {
    if (this._ref.value.has(value)) return;
    this._ref.value.add(value);
    triggerRef(this._ref);
  }

  delete(value: T) {
    this._ref.value.delete(value);
    triggerRef(this._ref);
  }

  toggle(value: T) {
    if (this._ref.value.has(value)) {
      this.delete(value);
    } else {
      this.add(value);
    }
    triggerRef(this._ref);
  }

  replace(newValues: T[]) {
    this._ref.value = new Set(newValues);
  }

  clear() {
    this._ref.value = new Set();
  }

  has(value: T) {
    return this._ref.value.has(value);
  }

  union(newValues: T[]) {
    this._ref.value = new Set([
      ...this._ref.value,
      ...newValues.filter((v) => !this._ref.value.has(v)),
    ]);
  }

  intersection(values: T[]) {
    this._ref.value = new Set([
      ...this._ref.value,
      ...values.filter((v) => this._ref.value.has(v)),
    ]);
  }

  difference(values: T[]) {
    this._ref.value = new Set(this.values.filter((v) => !values.includes(v)));
  }

  get ref() {
    return this._ref;
  }

  get values() {
    return Array.from(this._ref.value);
  }

  get size() {
    return this._ref.value.size;
  }
}
