<template>
  <div
    class="row no-wrap items-center justify-end gap-lg q-pa-md text-neutral-7 positions-summary-footer"
  >
    <div class="row no-wrap items-center gap-xs">
      <q-icon :name="icons.quotation.positionCount" size="16px" />
      <span class="text-caption">{{ offeredPositions.length }}</span>
      <q-tooltip :delay="1000">
        {{ $t("inquiryPositionsPage.positionsSummaryFooter.count") }}
      </q-tooltip>
    </div>
    <div class="row no-wrap items-center gap-xs">
      <q-icon :name="icons.quotation.totalPriceNet" size="16px" />
      <span class="text-caption">{{
        totalNetAmount
          ? formatCurrencyOrNull(locale, totalNetAmount, currency)
          : "-"
      }}</span>
      <q-tooltip :delay="1000">
        {{ $t("inquiryPositionsPage.positionsSummaryFooter.price") }}
      </q-tooltip>
    </div>
    <div
      v-if="organization?.useGrossMargins"
      class="row no-wrap items-center gap-xs"
    >
      <q-icon :name="icons.margin" size="16px" />
      <span class="text-caption"
        >{{ totalMargin ? `${totalMargin.toFixed(1)}` : "-" }} %</span
      >
      <q-tooltip :delay="1000">
        {{ $t("inquiryPositionsPage.positionsSummaryFooter.margin") }}
      </q-tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DEFAULT_CURRENCY } from "@/config/defaults";
import icons from "@/config/icons";
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { formatCurrencyOrNull } from "@/utils/currency";
import {
  calculateGroupTotalPrice,
  calculateGroupTotalMargin,
} from "@/utils/pricing";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { organization } = storeToRefs(useCurrentOrganizationStore());
const { offerPositionGroups } = storeToRefs(
  useCurrentOfferPositionGroupsStore()
);

const { locale } = useI18n();

const currency = computed(
  () => organization.value?.details.currency || DEFAULT_CURRENCY
);

const offeredPositions = computed(
  () =>
    offerPositionGroups.value
      ?.filter((opg) => opg.isOffered)
      .flatMap((group) => group.offerPositions) ?? []
);

const totalNetAmount = computed(() =>
  calculateGroupTotalPrice(offeredPositions.value)
);

const totalMargin = computed(() =>
  calculateGroupTotalMargin(offeredPositions.value)
);
</script>

<style scoped lang="scss">
.positions-summary-footer {
  border-top: 1px solid $neutral-3;
}
</style>
