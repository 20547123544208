import type { Document } from "./document";
import type { Email } from "./email";
import type { Tag } from "./tag";
import type { User } from "./user";
import type { Customer } from "@/types/customer";
import type { QuotationFile } from "@/types/quotation";

export interface ShortInquiry {
  id: number;
  shortCode: string;
  attachmentsCount: number;
  createdAt: Date;
  inbox: number;
  status: InquiryStatus;
  assignedUser: User | null;
  filenames: string[];
  progress: InquiryProgress;
  tags: Tag[];
  humanId: number;
  exportedAt: Date | null;

  requestedSubmissionDate: Date | null;
  requestedCustomerSubmissionDate: Date | null;

  buildingProject: string;
  buildingProjectId: string | null;
  email: Email | null;

  hasQuotation: boolean;
  discountRateProductsWithoutGroup: number | null;
  analyzedInInboxId: number | null;
}

export interface Inquiry extends ShortInquiry {
  documents: Document[];
  quotationFiles: QuotationFile[];
  inputChannel: InputChannel;
  nextInquiryId: number | null;
  previousInquiryId: number | null;
  possibleDuplicates: {
    id: number;
    shortCode: string;
    buildingProject: string;
    offerPositionGroupsCount: number;
  }[];
  isSubscribed: boolean;
  customer: Customer | null;
  externalCustomerId: string | null;
  customFields: InquiryCustomField[];
}

export type BaseInquiryStatus =
  | "ANALYZING"
  | "ANALYSIS_ERROR"
  | "REVIEWING"
  | "FINALIZED"
  | "ARCHIVED"
  | "EXPORTING"
  | "EXPORT_ERROR";

export const CHANGEABLE_BASE_INQUIRY_STATUSES: BaseInquiryStatus[] = [
  "REVIEWING",
  "ARCHIVED",
];

export const IN_PROGRESS_BASE_INQUIRY_STATUSES: BaseInquiryStatus[] = [
  "ANALYZING",
  "ANALYSIS_ERROR",
  "REVIEWING",
];

export const CAN_EDIT_BASE_INQUIRY_STATUSES: BaseInquiryStatus[] = [
  "ANALYSIS_ERROR",
  "REVIEWING",
];

export const CAN_EDIT_INQUIRY_RELATED_VALUES_STATUSES: BaseInquiryStatus[] = [
  "ANALYZING",
  "ANALYSIS_ERROR",
  "REVIEWING",
];

export const CAN_EDIT_POSITIONS_BASE_INQUIRY_STATUSES: BaseInquiryStatus[] = [
  "REVIEWING",
];

export const CAN_FINALIZE_BASE_INQUIRY_STATUSES: BaseInquiryStatus[] = [
  "REVIEWING",
];

export const CAN_EXPORT_BASE_INQUIRY_STATUSES: BaseInquiryStatus[] = [
  "REVIEWING",
  "FINALIZED",
  "EXPORT_ERROR",
];

export const HAS_ANALYSIS_RESULTS_BASE_INQUIRY_STATUSES: BaseInquiryStatus[] = [
  "REVIEWING",
  "FINALIZED",
  "ARCHIVED",
  "EXPORTING",
  "EXPORT_ERROR",
];

export const CAN_MOVE_TO_OTHER_INBOX_INQUIRY_STATUSES: BaseInquiryStatus[] = [
  "REVIEWING",
  "ARCHIVED",
];

export const UPDATING_BASE_INQUIRY_STATUSES: BaseInquiryStatus[] = [
  "ANALYZING",
  "EXPORTING",
];

export const CAN_NOT_EDIT_QUOTATION_FILES_STATUSES: BaseInquiryStatus[] = [
  "EXPORTING",
  "FINALIZED",
];

export interface InquiryStatus {
  id: string;
  name: string;
  originalStatus: BaseInquiryStatus;
  color: string;
  custom: boolean;
  isSelectable: boolean;
}

export interface InquiryProgress {
  total: number;
  offered: number;
  completed: number;
  own: number;
  external: number;
  neutral: number;
}

export interface InquiryCustomFieldChoice {
  label: string;
  value: string;
}

export interface InquiryCustomField {
  id: number;
  displayName: string;
  value: string;
  choices: InquiryCustomFieldChoice[];
}

export type InputChannel = "UPLOAD" | "EMAIL";

export interface InquiryCustomFieldUpdate {
  id: number;
  value: string;
}
