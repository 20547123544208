<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-form @submit="onSubmit">
        <q-card-section>
          <q-select
            ref="organizationSelectRef"
            :label="$t('Organization')"
            :disable="isLoading"
            :options="options"
            v-model="organization"
            input-debounce="0"
            use-input
            hide-selected
            @filter="filterFn"
          >
            <template #no-option>
              <q-item>
                <q-item-section class="text-grey">
                  {{ $t("switchOrganizationDialog.noResults") }}
                </q-item-section>
              </q-item>
            </template>
            <template #selected>
              {{ organization?.id }} - {{ organization?.name }}
            </template>
            <template #option="scope">
              <q-item v-bind="scope.itemProps">
                <q-item-section>
                  <q-item-label>
                    {{ scope.opt.id }} - {{ scope.opt.name }}
                  </q-item-label>
                  <q-item-label caption>{{
                    scope.opt.description
                  }}</q-item-label>
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            color="primary"
            :label="$t('Cancel')"
            @click="onDialogCancel"
          />
        </q-card-actions>
      </q-form>
      <q-inner-loading :showing="isLoading" />
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { listOrganizations } from "@/api/organization";
import { useRouteParams } from "@/composables/useRouteParams";
import type { Organization } from "@/types/organization";
import { QSelect, useDialogPluginComponent } from "quasar";
import { nextTick, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

const isLoading = ref(false);
const organization = ref<Organization | undefined>();
const organizations = ref<Organization[]>([]);
const options = ref<Organization[]>([]);
const organizationSelectRef = ref<typeof QSelect | null>(null);
const router = useRouter();
const routeParams = useRouteParams();

onMounted(loadData);

async function loadData() {
  isLoading.value = true;

  try {
    organizations.value = await listOrganizations();
    options.value = organizations.value;
    organization.value = organizations.value.find(
      (o) => o.id == routeParams.organizationId.value
    );
    watch(organization, onSubmit);
    await nextTick();
    organizationSelectRef.value?.focus();
  } finally {
    isLoading.value = false;
  }
}

defineEmits(useDialogPluginComponent.emits);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

async function onSubmit() {
  isLoading.value = true;

  try {
    router.push({
      name: "organization",
      params: { organizationId: organization.value?.id },
    });
  } finally {
    isLoading.value = false;
  }

  onDialogOK();
}

function filterFn(val: string, update: (fn: () => void) => void) {
  if (val === "") {
    update(() => {
      options.value = organizations.value;
    });
    return;
  }

  update(() => {
    const needles = val.toLowerCase().split(" ");
    options.value = organizations.value.filter((v) =>
      needles.every(
        (needle) =>
          v.name.toLowerCase().indexOf(needle) > -1 ||
          v.id.toString().toLowerCase().indexOf(needle) > -1
      )
    );
  });
}
</script>
