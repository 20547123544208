import api from "./api";
import dateFormat from "./dateFormat";
import documentInfo from "./documentInfo";
import inquiryStatus from "./inquiryStatus";
import documentTimeline from "./documentTimeline";
import documentsTable from "./documentsTable";
import email from "./email";
import fieldsDisplay from "./fieldsDisplay";
import fileUpload from "./fileUpload";
import general from "./general";
import inboxPage from "./inboxPage";
import inboxViews from "./inboxViews";
import leadActions from "./leadActions";
import leadSplitter from "./leadSplitter";
import login from "./login";
import moveFileDialog from "./moveFileDialog";
import organizationSettings from "./organizationSettings";
import sidebar from "./sidebar";
import new_ from "./new/index";
import password from "./password";
import members from "./members";

export default {
  ...new_,
  ...api,
  ...dateFormat,
  ...documentInfo,
  ...documentsTable,
  ...documentTimeline,
  ...inquiryStatus,
  ...email,
  ...fieldsDisplay,
  ...fileUpload,
  ...general,
  ...inboxPage,
  ...inboxViews,
  ...leadActions,
  ...leadSplitter,
  ...login,
  ...moveFileDialog,
  ...organizationSettings,
  ...sidebar,
  ...password,
  ...members,
};
