import { cmdOrCtrlPressed } from "@/utils/cmdOrCtrl";
import { onBeforeUnmount, onMounted } from "vue";

export function usePositionsTableKeyboardShortcuts({
  onEnter,
  onA,
  onF,
  onRightArrow,
  onLeftArrow,
  onDownArrow,
  onUpArrow,
  onShiftRightArrow,
  onShiftLeftArrow,
  onShiftDownArrow,
  onShiftUpArrow,
  onEscape,
  onCtrlOrCmdA,
}: {
  onEnter: () => unknown;
  onA: () => unknown;
  onF: () => unknown;
  onRightArrow: () => unknown;
  onLeftArrow: () => unknown;
  onUpArrow: () => unknown;
  onDownArrow: () => unknown;
  onShiftRightArrow: () => unknown;
  onShiftLeftArrow: () => unknown;
  onShiftUpArrow: () => unknown;
  onShiftDownArrow: () => unknown;
  onEscape: () => unknown;
  onCtrlOrCmdA: () => unknown;
}) {
  onMounted(() => {
    window.addEventListener("keydown", handleWindowKeydown);
  });
  onBeforeUnmount(() => {
    window.removeEventListener("keydown", handleWindowKeydown);
  });

  function handleWindowKeydown(event: KeyboardEvent) {
    if (noModifierKeys(event) && event.key === "ArrowDown") {
      onDownArrow();
    } else if (noModifierKeys(event) && event.key === "ArrowUp") {
      onUpArrow();
    } else if (noModifierKeys(event) && event.key === "Enter") {
      onEnter();
    } else if (noModifierKeys(event) && event.key === "ArrowRight") {
      onRightArrow();
    } else if (noModifierKeys(event) && event.key === "ArrowLeft") {
      onLeftArrow();
    } else if (shift(event) && event.key === "ArrowDown") {
      onShiftDownArrow();
    } else if (shift(event) && event.key === "ArrowUp") {
      onShiftUpArrow();
    } else if (shift(event) && event.key === "ArrowRight") {
      onShiftRightArrow();
    } else if (shift(event) && event.key === "ArrowLeft") {
      onShiftLeftArrow();
    } else if (noModifierKeys(event) && event.key === "a") {
      onA();
    } else if (noModifierKeys(event) && event.key === "f") {
      onF();
    } else if (noModifierKeys(event) && event.key === "Escape") {
      onEscape();
    } else if (cmdOrCtrlPressed(event) && event.key === "a") {
      onCtrlOrCmdA();
    } else {
      return; // not handled here: let event bubble up and don't prevent default
    }

    event.preventDefault();
    event.stopPropagation();
  }
}

function noModifierKeys(event: KeyboardEvent) {
  return !event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey;
}

function shift(event: KeyboardEvent) {
  return event.shiftKey && !event.altKey && !event.ctrlKey && !event.metaKey;
}
