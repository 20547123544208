export interface OrganizationDetails {
  companyName: string;
  street: string;
  postCode: string;
  region: string;
  town: string;
  country: string;
  countryCode: string;
  phoneNumber: string;
  faxNumber: string;
  email: string;
  website: string;
  vatTaxId: string;
  taxId: string;
  registerId: string;
  managingDirectors: string;
  bankName: string;
  bankBic: string;
  bankIban: string;
  currency: string;
  logo: string | null;
  initialQuotationSerialId: number | null;
  currentQuotationSerialId: number;
}

export interface Organization {
  id: number;
  name: string;
  description: string;
  showBuildingProjectId: boolean;
  showCustomerId: boolean;
  useCustomerData: boolean;
  useSupplierData: boolean;
  usePrices: boolean;
  useQuotationCreation: boolean;
  useProductGroupDiscounts: boolean;
  usePositionDiscounts: boolean;
  useGrossMargins: boolean;
  isBuildingProjectIdRequired: boolean;
  isCustomerIdRequired: boolean;
  availableExportType: availableExportTypeOptions;
  showManufacturerTypes: boolean;
  variantConfiguratorUrl: string | null;
  details: OrganizationDetails;
  showProductGroups: boolean;
  showExtraDownloadFormats: string[];
  quotationFileMaxSizeKb: number | null;
  quotationFileExtensions: string[];
}

export interface PartialOrganization
  extends Partial<Omit<Organization, "details">> {
  details?: Partial<OrganizationDetails>;
}

export interface OrganizationKeywords {
  id: number;
  positive: string[];
  negative: string[];
  own: string[];
  external: string[];
}

export interface keyable {
  [key: string]: any;
}

export interface OrganizationExport {
  exportFunction: exportFunctionType;
  renderFunction: renderFunctionType;
  configuration: keyable;
  isEnabled: boolean;
}

export type exportFunctionType = "sftp_export" | "rest_export";
export type renderFunctionType = "xml_idoc_orders05" | "json_renderer";

export const EXPORT_FUNCTION_TYPES: exportFunctionType[] = [
  "rest_export",
  "sftp_export",
];
export const RENDER_FUNCTION_TYPES: renderFunctionType[] = [
  "json_renderer",
  "xml_idoc_orders05",
];

export const EXPORT_FUNCTION_TYPES_LABELS = {
  sftp_export: "SFTP",
  rest_export: "REST",
};

export type availableExportTypeOptions = "ASYNC" | "SYNC" | null;

export const RENDER_FUNCTION_TYPES_LABELS = {
  xml_idoc_orders05: "IDoc XML",
  json_renderer: "JSON",
};

export enum ImportFunctionType {
  SFTP_IDOC = "sftp_idoc",
}

export const ImportFunctionOptions = [
  { label: "IDoc via SFTP", value: ImportFunctionType.SFTP_IDOC },
];

export interface DataImport {
  importFunction: ImportFunctionType;
  isEnabled: boolean;
  configuration: keyable;
}

export interface MonthlyUsage {
  month: number;
  year: number;
  amount: number;
}

export type BatchProductImportJobStatus =
  | "CREATED"
  | "PROCESSING"
  | "SUCCESS"
  | "INPUT_ERROR"
  | "FAILED";

export type BatchProductImportErrorCode =
  | "CANNOT_READ_EXCEL_FILE"
  | "MISSING_SHEET"
  | "COLUMN_NOT_UNIQUE"
  | "MISSING_COLUMNS"
  | "EXTRA_COLUMNS"
  | "TOO_MANY_ROWS"
  | "MISSING_VALUE"
  | "CANNOT_BE_ZERO"
  | "CANNOT_BE_NA"
  | "INVALID_BOOLEAN"
  | "INVALID_FLOAT"
  | "INVALID_UNIT"
  | "MISSING_UNIT_TRANSLATION"
  | "INVALID_STRING_FOR_COMMA_SEPARATED_FIELD";

export interface BatchProductImportJob {
  id: number;
  originalFilename: string;
  status: BatchProductImportJobStatus;
  errorCode: BatchProductImportErrorCode | null;
  errorParams: object | null;
}
