<template>
  <div
    class="full-width row items-start no-wrap q-py-sm new-offer-position-container"
    @keydown.up.stop
    @keydown.down.stop
    @keydown.left.stop
    @keydown.right.stop
  >
    <single-product-input
      ref="productInputEl"
      :group="group"
      :position="undefined"
      v-model:product="product"
      class="col"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @keydown.stop.left
      @keydown.stop.right
      @keydown.stop.escape="productInputEl?.blur()"
    />
  </div>
</template>

<script setup lang="ts">
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import type { OfferPositionGroup } from "@/types/offerPositionGroup";
import type { Product } from "@/types/product";
import { getAmountAndUnitForNewOfferPosition } from "@/utils/productUnitAmount";
import { ref, watch } from "vue";
import SingleProductInput from "./SingleProductInput.vue";

const props = defineProps<{
  group: OfferPositionGroup;
}>();

const emit = defineEmits(["focus", "blur", "offerPositionAdded"]);

const product = ref<Product | null>(null);
const productInputEl = ref<typeof SingleProductInput | null>(null);

const { addOfferPosition } = useCurrentOfferPositionGroupsStore();

const addOfferPositionIfProductSelected = async () => {
  if (!product.value) return;

  const [newAmount, newUnit] = getAmountAndUnitForNewOfferPosition(
    props.group.boqAmount,
    props.group.boqUnit,
    product.value
  );

  addOfferPosition(props.group.id, {
    product: product.value,
    amount: newAmount,
    unit: newUnit,
    unitPrice: product.value.listPrice,
  });

  resetOfferPositionContainer();

  emit("offerPositionAdded");
};
watch(product, addOfferPositionIfProductSelected);

function resetOfferPositionContainer() {
  product.value = null;
}

function focus() {
  productInputEl.value?.softFocus();
}

defineExpose({ focus });
</script>

<style scoped lang="scss">
.new-offer-position-container {
  padding-left: calc(
    $offer-position-index-container-width + 36px
  ); // align with existing offer positions
  padding-right: 45.15px; // align with existing offer positions
}
</style>
