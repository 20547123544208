<template>
  <router-link
    :to="{
      name: targetRouteName,
      params: { supplierRfqId: supplierRfq.id },
      query: currentRoute.query,
    }"
    class="no-decoration router-link"
    replace
  >
    <div
      class="row no-wrap items-center gap-md q-px-md q-py-sm supplier-rfq-item"
      :class="{ 'bg-neutral-2': supplierRfq.id === currentSupplierRfqId }"
    >
      <supplier-rfq-status-menu
        :status="supplierRfq.status"
        @markAsAnswered="updateSupplierRfq({ status: 'ANSWERED' })"
        @markAsUnanswered="updateSupplierRfq({ status: 'SENT' })"
      />
      <div
        v-if="!isInquirySupplierRfqsPage"
        class="supplier-rfq-item__short-code ellipsis row no-wrap items-center"
      >
        <span class="ellipsis col">
          {{ supplierRfq.inquiry.shortCode }} -
          {{ supplierRfq.inquiry.buildingProject }}
        </span>
        <q-btn
          flat
          dense
          icon="launch"
          size="xs"
          color="neutral-5"
          class="no-decoration"
          :to="{
            name: 'inquiry-positions',
            params: {
              inboxId: supplierRfq.inquiry.inboxId,
              inquiryId: supplierRfq.inquiry.id,
              inboxView: 'all',
            },
          }"
        >
          <q-tooltip :delay="1000">
            {{ $t("supplierRfqsPage.openInquiry") }}
          </q-tooltip>
        </q-btn>
      </div>
      <div
        class="supplier-rfq-item__supplier ellipsis row no-wrap items-center gap-sm"
        :style="{ 'flex-grow': isInquirySupplierRfqsPage ? 1 : 0 }"
      >
        <div class="ellipsis">{{ supplierRfq.supplier.name }}</div>
      </div>
      <supplier-rfq-dates-display
        :supplier-rfq="supplierRfq"
        class="supplier-rfq-item__dates"
      />
      <user-select-button
        icon-only
        :user="supplierRfq.assignedUser"
        class="supplier-rfq-item__user"
        @update:user="
          (user) =>
            updateSupplierRfq({
              assignedUser: user?.id ? user : null,
            })
        "
        @click.left.stop.prevent
        :store="orgUsersStore"
      />
    </div>
    <q-menu context-menu>
      <q-item dense clickable v-close-popup @click="onDelete">
        <q-item-section>
          <div class="row items-center gap-sm no-wrap">
            <q-icon :name="icons.delete" color="neutral-5" size="20px" />
            {{ $t("supplierRfqsPage.deleteSupplierRfq") }}
          </div>
        </q-item-section>
      </q-item>
    </q-menu>
  </router-link>
</template>

<script setup lang="ts">
import UserSelectButton from "@/components/User/UserSelectButton.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import icons from "@/config/icons";
import { useCurrentOrganizationUsersStore } from "@/stores/currentOrganizationUsers";
import { useCurrentSupplierRfqsStore } from "@/stores/currentSupplierRfqs";
import type { SupplierRFQ } from "@/types/supplierRfq";
import { useQuasar } from "quasar";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import SupplierRfqStatusMenu from "../SupplierRfqStatusMenu.vue";
import SupplierRfqDatesDisplay from "./SupplierRfqDatesDisplay.vue";

const props = defineProps<{
  supplierRfq: SupplierRFQ;
}>();

const store = useCurrentSupplierRfqsStore();

const orgUsersStore = useCurrentOrganizationUsersStore();

const { supplierRfqId: currentSupplierRfqId } = useRouteParams();

const router = useRouter();
const { currentRoute } = router;

const targetRouteName = computed(() => {
  const currentRouteName = String(currentRoute.value.name);

  if (currentRouteName.endsWith("current-supplier-rfq")) {
    return currentRouteName;
  }
  return `${currentRouteName}.current-supplier-rfq`;
});

const isInquirySupplierRfqsPage = computed(() => {
  return currentRoute.value.name
    ?.toString()
    .startsWith("inquiry-supplier-rfqs");
});

const q = useQuasar();
const { t } = useI18n();

async function onDelete() {
  if (props.supplierRfq.status === "DRAFT") {
    await onDeleteConfirm();
  } else {
    q.dialog({
      title: t("supplierRfqsPage.confirmDeleteSentSupplierRfq.title"),
      message: t("supplierRfqsPage.confirmDeleteSentSupplierRfq.message"),
      ok: {
        dense: true,
        label: t("Delete"),
        color: "negative",
      },
      cancel: {
        flat: true,
        dense: true,
      },
    }).onOk(onDeleteConfirm);
  }
}

async function onDeleteConfirm() {
  await store.deleteSupplierRfq(props.supplierRfq.id);
  const currentRouteName = currentRoute.value.name?.toString();
  if (!currentRouteName) return;
  if (!currentRouteName.endsWith("current-supplier-rfq")) return;

  const newRouteName = currentRouteName.replace(/\.current-supplier-rfq$/, "");
  router.push({
    name: newRouteName,
    params: { supplierRfqId: null },
    query: currentRoute.value.query,
  });
}

async function updateSupplierRfq(data: Partial<SupplierRFQ>) {
  await store.updateSupplierRfq(props.supplierRfq.id, data);
}
</script>

<style scoped lang="scss">
.supplier-rfq-item {
  cursor: pointer;

  &:hover {
    background-color: $neutral-2;
  }

  &__short-code {
    flex-grow: 1;
    flex-shrink: 1;
  }

  &__supplier {
    width: 200px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__dates {
    width: 120px;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.router-link {
  color: inherit;
}
</style>
