<template>
  <q-btn outline dense color="neutral-5" class="margin-display">
    <div class="row no-wrap items-center full-width">
      <q-icon
        :name="icons.margin"
        size="xs"
        :color="margin && margin < 0 ? 'negative' : 'neutral-7'"
      />
      <q-space />
      <div
        class="text-neutral-7 ellipsis"
        :class="{ 'text-negative': margin && margin < 0 }"
      >
        <span v-if="margin !== null">
          {{ margin.toFixed(1) }}
        </span>
        <span v-else>-</span>
        <span class="q-ml-xs">%</span>
      </div>
    </div>
    <q-tooltip :delay="1000">
      {{ $t("offerPosition.margin") }}
    </q-tooltip>
    <pricing-menu :group-id="groupId" :position="position" />
  </q-btn>
</template>

<script setup lang="ts">
import icons from "@/config/icons";
import type { OfferPosition } from "@/types/offerPosition";
import { calculatePositionMargin } from "@/utils/pricing";
import { computed } from "vue";
import PricingMenu from "./PricingMenu.vue";

const props = defineProps<{
  groupId: number;
  position: OfferPosition;
}>();

const margin = computed(() => {
  return calculatePositionMargin(
    props.position.unitPrice,
    props.position.discountRate,
    props.position.purchasePrice
  );
});
</script>

<style scoped lang="scss">
.margin-display {
  min-width: $discount-margin-input-width;
  font-size: inherit;
  font-weight: inherit;
  border-radius: 4px;
}
</style>
