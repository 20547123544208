import * as api from "@/api/inbox";
import { useRouteParams } from "@/composables/useRouteParams";
import type { NewInbox, ShortInbox } from "@/types/inbox";
import { isEqual } from "lodash";
import { defineStore, storeToRefs } from "pinia";
import { computed, ref, watch, type ComputedRef } from "vue";
import { useUserSettingsStore } from "./userSettings";

export const useInboxesStore = defineStore("inboxes", () => {
  const inboxes = ref<ShortInbox[] | null>(null);

  const { organizationId } = useRouteParams();

  const userSettingsStore = useUserSettingsStore();
  const { userSettings } = storeToRefs(userSettingsStore);
  const { updateFrontendPreferences } = userSettingsStore;

  const inboxOrder = computed(() => {
    const orderByOrgId =
      userSettings.value?.frontendPreferences?.sidebarInboxOrderByOrgId || {};
    if (orderByOrgId[organizationId.value]) {
      return orderByOrgId[organizationId.value];
    }
    // legacy order, not by organization - kept for compatibility
    return userSettings.value?.frontendPreferences?.sidebarInboxOrder || [];
  });

  async function loadInboxes() {
    if (isNaN(organizationId.value)) return;

    try {
      const inboxesFromApi = await api.listInboxes(organizationId.value);
      inboxes.value = sortInboxes(inboxesFromApi, inboxOrder.value);

      updateInboxOrderPreferenceIfChanged(inboxesFromApi.map((i) => i.id));
    } catch (error: any) {
      if (error.response?.status === 404) {
        // 404 errors are handled by currentOrganization store
        inboxes.value = null;
        return;
      } else {
        throw error;
      }
    }
  }

  watch(organizationId, loadInboxes, { immediate: true });

  watch(inboxOrder, (newOrder) => {
    if (!newOrder) return;
    if (!inboxes.value) return;
    inboxes.value = sortInboxes(inboxes.value, newOrder);
  });

  async function updateInboxOrderPreferenceIfChanged(order: number[]) {
    if (!userSettings.value?.frontendPreferences) return;

    const sidebarInboxOrderByOrgId =
      userSettings.value.frontendPreferences.sidebarInboxOrderByOrgId || {};

    if (isEqual(order, sidebarInboxOrderByOrgId[organizationId.value] || []))
      return;

    await updateFrontendPreferences({
      sidebarInboxOrderByOrgId: {
        ...sidebarInboxOrderByOrgId,
        [organizationId.value]: order,
      },
    });
  }

  const currentInbox = computed(() => {
    const { inboxId } = useRouteParams();
    return getById(inboxId.value).value;
  });

  async function getInbox(inboxId: number) {
    return await api.getInbox(organizationId.value, inboxId);
  }

  function getById(inboxId: number) {
    return computed(
      () => inboxes.value?.find((inbox) => inbox.id === inboxId) || null
    ) as ComputedRef<ShortInbox | null>;
  }

  async function addInbox(inbox: NewInbox) {
    if (!inboxes.value) return;
    const newInbox = await api.createInbox(organizationId.value, inbox);
    inboxes.value.push(newInbox);
  }

  async function deleteInbox(inboxId: number) {
    if (!inboxes.value) return;
    await api.deleteInbox(organizationId.value, inboxId);
    inboxes.value = inboxes.value.filter((inbox) => inbox.id !== inboxId);
  }

  async function updateInbox(inboxId: number, data: any) {
    if (!inboxes.value) return;
    const updatedInbox = await api.updateInbox(
      organizationId.value,
      inboxId,
      data
    );

    // TODO: This triggers the watcher at
    //  frontend/src/pages/Settings/Inbox/GeneralSettings.vue#L105
    //  which results in an unnecessary GET inbox call
    inboxes.value = inboxes.value.map((i) =>
      i.id === updatedInbox.id ? updatedInbox : i
    );
  }

  return {
    inboxes,
    currentInbox,
    getById,
    getInbox,
    addInbox,
    deleteInbox,
    updateInbox,
    updateInboxOrderPreferenceIfChanged,
  };
});

function sortInboxes(inboxes: ShortInbox[], order: number[]): ShortInbox[] {
  return inboxes.sort((a, b) => {
    const aIndex = order.indexOf(a.id);
    const bIndex = order.indexOf(b.id);

    if (aIndex === -1) {
      return 1;
    }

    if (bIndex === -1) {
      return -1;
    }

    return aIndex - bIndex;
  });
}
