<template>
  <inquiry-values-banner
    icon="sym_r_file_copy"
    color="primary"
    :title="$t('inquiryPage.possibleDuplicatesBanner.title')"
  >
    <div>
      {{ $t("inquiryPage.possibleDuplicatesBanner.possibleDuplicateOf") }}
    </div>
    <div class="flex column no-wrap q-mt-sm q-pl-md">
      <router-link
        v-for="duplicate in sortedPossibleDuplicates"
        :key="duplicate.id"
        :to="{ name: 'inquiry', params: { inquiryId: duplicate.id } }"
        class="text-primary-9 no-decoration"
      >
        <div class="q-my-xs flex row no-wrap items-center duplicate-link">
          <q-icon color="primary-9" :name="'sym_r_file_copy'" class="q-mr-xs" />
          <div>
            {{
              [duplicate.shortCode, duplicate.buildingProject]
                .filter((v) => v?.length > 0)
                .join(" • ")
            }}
            <i18n-t
              keypath="inquiryPage.possibleDuplicatesBanner.positionsCount"
              tag="span"
              scope="global"
              :plural="duplicate.offerPositionGroupsCount"
            />
          </div>
        </div>
      </router-link>
    </div>
  </inquiry-values-banner>
</template>

<script setup lang="ts">
import type { Inquiry } from "@/types/inquiry";
import InquiryValuesBanner from "./InquiryValuesBanner.vue";
import { computed } from "vue";
import { I18nT } from "vue-i18n";

type ExtendedInquiry = Pick<Inquiry, "id" | "shortCode" | "buildingProject"> & {
  offerPositionGroupsCount: number;
};

const props = defineProps<{
  possibleDuplicates: ExtendedInquiry[];
}>();

const sortedPossibleDuplicates = computed(() => {
  const possibleDuplicates = props.possibleDuplicates;
  possibleDuplicates.sort((a, b) => b.id - a.id);
  return possibleDuplicates;
});
</script>

<style scoped lang="scss">
.duplicate-link:hover > div {
  text-decoration: underline;
}
</style>
