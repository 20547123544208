<template>
  <file-dropzone
    v-if="inquiry"
    :accept="organization?.quotationFileExtensions.join(', ') || ''"
    @drop="uploadQuotationFile"
    class="rounded-borders"
  >
    <template #overlay>
      <div>{{ $t("inquiryPage.quotationFiles.uploadFile") }}</div>
    </template>
    <q-card flat bordered v-if="inquiry.quotationFiles.length > 0">
      <q-list dense separator>
        <q-item
          v-for="quotationFile in inquiry.quotationFiles.filter(
            (file) => file.isAttachment
          )"
          :key="quotationFile.id"
          :clickable="!quotationFile.temporaryId"
          @click.stop.prevent="
            quotationFile.temporaryId
              ? undefined
              : downloadQuotationFile(inquiry.id, quotationFile.id)
          "
        >
          <q-item-section>
            <q-item-label class="row no-wrap items-center">
              <q-icon name="sym_r_draft" class="q-mr-sm" color="neutral-8" />
              <filename-display :filename="quotationFile.filename" />
            </q-item-label>
          </q-item-section>
          <q-item-section side right>
            <q-btn
              v-if="!quotationFile.temporaryId"
              flat
              dense
              size="sm"
              icon="sym_r_delete"
              @click.stop.prevent="
                removeQuotationFile(inquiry!.id, quotationFile)
              "
            />
            <q-circular-progress
              v-else
              indeterminate
              size="16px"
              color="neutral-7"
              class="q-mx-xs"
            />
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>
    <div class="q-mt-xs row no-wrap justify-start">
      <q-btn
        flat
        dense
        color="neutral-8"
        icon="sym_r_add"
        size="md"
        @click.stop.prevent="fileInputEl?.click()"
      >
        {{ $t("inquiryPage.quotationFiles.uploadFile") }}
      </q-btn>
      <input
        type="file"
        multiple
        style="display: none"
        ref="fileInputEl"
        :accept="organization?.quotationFileExtensions.join(', ') || ''"
        @change="(event: any) => uploadQuotationFile(event.target?.files)"
      />
    </div>
  </file-dropzone>
</template>

<script setup lang="ts">
import {
  createQuotationFile,
  deleteQuotationFile,
  downloadQuotationFile,
} from "@/api/quotationFile";
import FileDropzone from "@/components/FileDropzone.vue";
import FilenameDisplay from "@/components/FilenameDisplay.vue";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { type QuotationFile } from "@/types/quotation";
import { isAxiosError } from "axios";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";

const { inquiry } = storeToRefs(useCurrentInquiryStore());
const { organization } = storeToRefs(useCurrentOrganizationStore());
const fileInputEl = ref<HTMLInputElement | null>(null);
const q = useQuasar();
const { t } = useI18n();

async function removeQuotationFile(
  inquiryId: number,
  quotationFile: QuotationFile
) {
  if (!inquiry.value || quotationFile.temporaryId) {
    throw new Error("Inquiry not found");
  }

  q.dialog({
    title: t("inquiryPage.quotationFiles.removeFile"),
    message: t("inquiryPage.quotationFiles.removeFileConfirmation", {
      filename: quotationFile.filename,
    }),
    ok: {
      label: t("inquiryPage.quotationFiles.removeFileOk"),
      color: "negative",
      dense: true,
    },
    cancel: {
      label: t("inquiryPage.quotationFiles.removeFileCancel"),
      dense: true,
      flat: true,
    },
  }).onOk(async () => {
    if (!inquiry.value) throw new Error("Inquiry not found");

    inquiry.value.quotationFiles = inquiry.value.quotationFiles.filter(
      (file) => file.id !== quotationFile.id
    );
    await deleteQuotationFile(inquiryId, quotationFile.id);
  });
}

async function uploadQuotationFile(files: FileList) {
  const uploads = [];
  for (const file of files) {
    uploads.push(uploadSingleQuotationFile(file));
  }
  await Promise.all(uploads);
}

async function uploadSingleQuotationFile(file: File) {
  if (!file) {
    return;
  }

  const maxFileSizeKb = organization.value?.quotationFileMaxSizeKb || 102400;
  if (file.size > maxFileSizeKb * 1024) {
    q.notify({
      message: t("inquiryPage.quotationFiles.errors.FILE_TOO_LARGE", {
        maxSize: maxFileSizeKb,
      }),
      color: "negative",
    });
    return;
  }
  const temporaryId = crypto.randomUUID();

  const tempQuotationFile: QuotationFile = reactive({
    id: -1,
    filename: file.name,
    fileFormat: null,
    isAttachment: true,
    temporaryId,
  });
  inquiry.value!.quotationFiles.push(tempQuotationFile);
  try {
    const newQuotationFile = await createQuotationFile(inquiry.value!.id, file);
    Object.assign(tempQuotationFile, newQuotationFile);
    tempQuotationFile.temporaryId = undefined;
  } catch (e: any) {
    if (isAxiosError(e) && e.response?.data) {
      const errorCode = e.response?.data?.[0];
      const errorMessage =
        t(`inquiryPage.quotationFiles.errors.${errorCode}`, {
          maxSize: organization.value!.quotationFileMaxSizeKb,
          allowedExtensions:
            organization.value!.quotationFileExtensions.join(", "),
        }) || t("inquiryPage.quotationFiles.errors.UNKNOWN_ERROR");
      q.notify({
        message: errorMessage,
        color: "negative",
      });
      inquiry.value!.quotationFiles = inquiry.value!.quotationFiles.filter(
        (file) => file.temporaryId !== temporaryId
      );
    } else {
      throw e;
    }
  }
}
</script>

<style scoped>
.button-spacer {
  width: 25.15px;
}
</style>
