<template>
  <div class="column no-wrap page-below-header-bar">
    <div class="col overflow-y-auto">
      <email-display
        v-if="isEmailActive && inquiry?.email"
        :email="inquiry.email"
        :inbox="inbox ?? undefined"
      />
      <pdf-display
        v-else-if="
          activeDocument && activeDocument.fileType == 'PDF' && activeFile
        "
        ref="pdfDisplayEl"
        :file="activeFile"
        :document="activeDocument"
        :offer-position-groups="currentOfferPositionGroups"
        class="pdf-viewer"
      />
      <gaeb-viewer
        v-else-if="
          activeDocument &&
          activeDocument.fileType == 'GAEB' &&
          activeDocument.gaebDocumentId
        "
        ref="gaebViewerEl"
        :document="activeDocument"
        :use-selection="true"
        class="pdf-viewer"
      />
    </div>
    <document-tabs
      v-if="
        (boqs && activeDocumentId !== undefined && boqs.length > 0) ||
        hasEmailPositions
      "
      :email="inquiry?.email"
      :show-email-tab="hasEmailPositions ?? false"
      v-model:is-email-active="isEmailActive"
      :documents="boqs"
      v-model:active-document-id="activeDocumentId"
    />
  </div>
</template>

<script setup lang="ts">
import { getDocumentFile } from "@/api/document";
import DocumentTabs from "@/components/DocumentTabs/DocumentTabs.vue";
import GaebViewer from "@/components/GaebViewer/GaebViewer.vue";
import { usePositionsEvents } from "@/composables/usePositionsEvents";
import { useRouteParams } from "@/composables/useRouteParams";
import { MAX_WAIT_FOR_PDF_DISPLAY_EL_MS } from "@/config/constants";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import { waitFor } from "@/utils/waitFor";
import { storeToRefs } from "pinia";
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  shallowReactive,
  watch,
} from "vue";
import PdfDisplay from "../PdfDisplay/PdfDisplay.vue";

import EmailDisplay from "@/components/EmailDisplay/EmailDisplay.vue";
import { useCurrentInboxStore } from "@/stores/currentInbox";

const { inboxId, inquiryId } = useRouteParams();

const { inquiry } = storeToRefs(useCurrentInquiryStore());
const { inbox } = storeToRefs(useCurrentInboxStore());
const { visibleOfferPositionGroups, hasEmailPositions } = storeToRefs(
  useCurrentOfferPositionGroupsStore()
);

const documentFiles = shallowReactive<Map<number, File>>(new Map());

const boqs = computed(
  () => inquiry.value?.documents.filter((doc) => doc.isBoq) || []
);

function getInitialActiveDocumentId() {
  if (hasEmailPositions.value) return null;
  return boqs.value[0]?.id ?? null;
}

const isEmailActive = ref(hasEmailPositions.value);
const activeDocumentId = ref<number | null>(getInitialActiveDocumentId());

watch([hasEmailPositions, boqs], () => {
  isEmailActive.value = hasEmailPositions.value;
  activeDocumentId.value = getInitialActiveDocumentId();
});

const activeDocument = computed(() => {
  return boqs.value.find((document) => document.id === activeDocumentId.value);
});

const activeFile = computed(() => {
  if (!activeDocumentId.value) return;
  return documentFiles.get(activeDocumentId.value);
});

const currentOfferPositionGroups = computed(() => {
  if (activeDocumentId.value === undefined) return [];
  return (
    visibleOfferPositionGroups.value?.filter(
      (group) => group.documentId === activeDocumentId.value
    ) || []
  );
});

watch(
  activeDocument,
  async (newActiveDocument) => {
    if (!newActiveDocument) return;
    if (documentFiles.has(newActiveDocument.id)) return;
    if (newActiveDocument.fileType == "PDF") {
      const file = await getDocumentFile(
        inboxId.value,
        inquiryId.value,
        newActiveDocument.id
      );
      if (!file) return;
      documentFiles.set(newActiveDocument.id, file);
    }
  },
  { immediate: true }
);

const pdfDisplayEl = ref<typeof PdfDisplay | null>(null);
const gaebViewerEl = ref<typeof GaebViewer | null>(null);

const displayEl = computed(() => {
  if (activeDocument.value?.fileType === "PDF") return pdfDisplayEl.value;
  if (activeDocument.value?.fileType === "GAEB") return gaebViewerEl.value;
  return null;
});

const events = usePositionsEvents();
onMounted(() => {
  events.on("tableRowClick", activateDocumentTab);
});
onBeforeUnmount(() => {
  events.off("tableRowClick", activateDocumentTab);
});

async function activateDocumentTab(spotlightId: string | null) {
  if (!spotlightId) return;

  const group = visibleOfferPositionGroups.value?.find(
    (group) => group.spotlightId === spotlightId
  );

  if (!group) return;

  if (!group.documentId && !group.emailId) {
    // this is a manually created group, so we don't want to change the active document
    return;
  }

  isEmailActive.value = group.emailId !== null;
  activeDocumentId.value = group.documentId;

  await nextTick();
  await nextTick();
  const displayElRef = await waitFor(
    () => displayEl.value,
    MAX_WAIT_FOR_PDF_DISPLAY_EL_MS
  );
  if (!displayElRef) {
    console.warn("Waiting for displayEl timed out");
    return;
  }
  displayElRef.scrollToHighlight(spotlightId);
}
</script>

<style scoped lang="scss">
.pdf-viewer {
  height: 100%;
  flex-shrink: 1;
}
</style>
