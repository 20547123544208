import { client } from "@/api/client/client";
import { DEFAULT_PAGE_SIZE_FOR_SUPPLIER_RFQS } from "@/config/constants";
import type {
  SupplierRFQ,
  SupplierRFQStatus,
  SupplierRFQWrite,
} from "@/types/supplierRfq";
import { format, formatISO, parse, parseISO } from "date-fns";
import { parseCursor } from "./utils";

type ApiSupplierRFQ = {
  dueDate: string | null;
  sentAt: string | null;
} & Omit<SupplierRFQ, "dueDate" | "sentAt">;

type ApiSupplierRFQWrite = {
  dueDate: string | null;
  sentAt: string | null;
} & Omit<SupplierRFQWrite, "dueDate" | "sentAt">;

export interface ListSupplierRfqFilterOptions {
  status?: SupplierRFQStatus[];
  assignedUserId?: (number | null)[];
  inquiryId?: number;
  buildingProject?: string;
  inquiryShortCode?: string;
  supplierId?: number[];
}

export async function listSupplierRfqs(
  organizationId: number,
  pageSize: number = DEFAULT_PAGE_SIZE_FOR_SUPPLIER_RFQS,
  cursor: string | null = null,
  filterOptions: ListSupplierRfqFilterOptions = {},
  returnCount: boolean = true
): Promise<{
  data: SupplierRFQ[];
  next: string | null;
  previous: string | null;
  count: number | null;
}> {
  const response = await client.get(
    `/organizations/${organizationId}/supplier_rfqs/`,
    {
      params: {
        page_size: pageSize,
        page_result_count: returnCount,
        cursor,
        ...processFilterOptions(filterOptions),
      },
    }
  );

  return {
    data: response.data.results.map(postprocessSupplierRfq),
    next: parseCursor(response.data.next),
    previous: parseCursor(response.data.previous),
    count: response.data.count,
  };
}

export async function getSupplierRfq(
  inquiryId: number,
  supplierRfqId: number
): Promise<SupplierRFQ> {
  const response = await client.get(
    `/inquiries/${inquiryId}/supplier_rfqs/${supplierRfqId}/`
  );

  return postprocessSupplierRfq(response.data);
}

export async function updateSupplierRfq(
  inquiryId: number,
  supplierRfqId: number,
  data: Partial<SupplierRFQWrite>
): Promise<SupplierRFQ> {
  const processedData = preprocessSupplierRfq(data);
  const response = await client.patch(
    `/inquiries/${inquiryId}/supplier_rfqs/${supplierRfqId}/`,
    processedData
  );

  return postprocessSupplierRfq(response.data);
}

export async function addOpgsToSupplierRfq(
  inquiryId: number,
  supplierId: number,
  offerPositionGroupIds: number[]
): Promise<SupplierRFQ> {
  const response = await client.post(
    `/inquiries/${inquiryId}/supplier_rfqs/add_opgs_to_draft/`,
    {
      supplierId,
      inquiryId,
      offer_position_groups: offerPositionGroupIds,
    }
  );

  return postprocessSupplierRfq(response.data);
}

export async function removeOpgsFromSupplierRfq(
  inquiryId: number,
  supplierId: number,
  offerPositionGroupIds: number[]
): Promise<void> {
  await client.post(
    `/inquiries/${inquiryId}/supplier_rfqs/remove_opgs_from_draft/`,
    {
      supplierId,
      inquiryId,
      offer_position_groups: offerPositionGroupIds,
    }
  );
}

export async function sendSupplierRfq(
  inquiryId: number,
  supplierRfqId: number
): Promise<SupplierRFQ> {
  const response = await client.post(
    `/inquiries/${inquiryId}/supplier_rfqs/${supplierRfqId}/send/`
  );

  return postprocessSupplierRfq(response.data);
}

export async function deleteSupplierRfq(
  inquiryId: number,
  supplierRfqId: number
): Promise<void> {
  await client.delete(
    `/inquiries/${inquiryId}/supplier_rfqs/${supplierRfqId}/`
  );
}

function preprocessSupplierRfq(
  object: Partial<SupplierRFQWrite>
): Partial<ApiSupplierRFQWrite> {
  const { dueDate, sentAt, ...rest } = object;

  return {
    ...rest,
    dueDate: dueDate ? format(dueDate, "yyyy-MM-dd") : dueDate,
    sentAt: sentAt ? formatISO(sentAt) : sentAt,
  };
}

function postprocessSupplierRfq(object: ApiSupplierRFQ): SupplierRFQ {
  const { dueDate, sentAt, ...rest } = object;

  return {
    ...rest,
    dueDate: dueDate ? parse(dueDate, "yyyy-MM-dd", new Date()) : null,
    sentAt: sentAt ? parseISO(sentAt) : null,
  };
}

function processFilterOptions(filterOptions: ListSupplierRfqFilterOptions) {
  const { assignedUserId, status, supplierId, ...rest } = filterOptions;

  return {
    ...rest,
    assignedUserId: assignedUserId
      ?.map((id) => (id === null ? "null" : id))
      .join(","),
    status: status?.join(","),
    supplierId: supplierId?.join(","),
  };
}
