<template>
  <div>
    <q-item
      ref="headerEl"
      dense
      clickable
      :v-ripple="!isActive"
      expand-icon-toggle
      :class="headerClass"
      :tabindex="0"
      @mousedown="$emit('headerclick', $event)"
      :active="false"
      :focused="false"
    >
      <q-item-section>
        <div class="row no-wrap full-width justify-start items-center">
          <toggle-offered-button
            :is-offered="group.isOffered"
            :disable="disabled"
            @toggle="$emit('setOffered', !group.isOffered)"
            @mousedown.right.stop="$emit('headerclick', $event)"
          />
          <div
            ref="referenceIdEl"
            class="positions-table-item-reference-id ellipsis q-mr-sm"
            style="outline: none"
            :class="{ 'cursor-text': canHeaderBeEdited }"
            :contenteditable="canHeaderBeEdited"
            @click="focusReferenceIdEl"
            @blur="handleReferenceIdElBlur"
            @keydown.left.stop
            @keydown.right.stop
            @keydown.a.stop
            @keydown.f.stop
          >
            {{ group.boqReferenceId }}
          </div>
          <div
            ref="shortTextEl"
            @click="focusShortTextEl"
            @blur="handleShortTextElBlur"
            class="positions-table-item-short-text col no-ellipsis-while-editing"
            :contenteditable="canHeaderBeEdited"
            :class="{
              'cursor-text': canHeaderBeEdited,
              ellipsis: !canHeaderBeEdited,
            }"
            style="outline: none"
            @keydown.left.stop
            @keydown.right.stop
            @keydown.a.stop
            @keydown.f.stop
          >
            {{ group.boqTextShort }}
          </div>
          <supplier-select-button
            v-if="organization?.useSupplierData"
            :group="group"
            :disabled="disabled"
            @mousedown.left.stop="$emit('inputclick')"
            class="q-mr-sm"
          />
          <div class="positions-table-item-rest row no-wrap items-center">
            <multi-product-input
              ref="productInputEl"
              :offer-position-group="group"
              :disabled="disabled"
              class="positions-table-item-quick-input ellipsis"
              @focus="isActive = true"
              @blur="isActive = false"
              @escape="productInputEl?.blur()"
              @mousedown.left.stop="$emit('activate')"
              @keydown.stop.a
              @keydown.stop.f
              @keydown.stop.left
              @keydown.stop.right
              @keydown.stop.escape="productInputEl?.blur()"
              :tabindex="0"
            />
            <div
              class="positions-table-item-amount q-mx-sm"
              v-if="!group.isManuallyCreated"
            >
              {{ group.boqAmount }}
              {{ group.boqUnit?.length && $t(`productUnit.${group.boqUnit}`) }}
            </div>
            <div class="q-ml-lg" v-else />
            <div class="col-auto">
              <q-checkbox
                dense
                size="sm"
                v-model="isCompletedCheckbox"
                color="neutral-7"
                :disable="disabled"
                :tabindex="0"
                @mousedown.left.stop
                @mousedown.right.stop="$emit('headerclick', $event)"
              >
                <q-tooltip :delay="1000">
                  {{
                    group.isCompleted
                      ? $t("inquiryPositionsPage.offerPositionGroup.unfinalize")
                      : $t("inquiryPositionsPage.offerPositionGroup.finalize")
                  }}
                  <kbd class="kbd-dark q-ml-xs">f</kbd>
                </q-tooltip>
              </q-checkbox>
            </div>
            <q-icon
              name="sym_r_warning"
              size="1rem"
              color="warning"
              v-if="
                group.isOffered &&
                group.offerPositions.some(
                  (position) => position.amount === null
                )
              "
            >
              <q-tooltip :delay="1000">
                {{
                  $t("inquiryPositionsPage.offerPositionGroup.missingAmount")
                }}
              </q-tooltip>
            </q-icon>
            <q-btn
              v-if="group.isManuallyCreated"
              :disable="disabled"
              dense
              flat
              size="sm"
              icon="sym_r_delete"
              color="neutral-7"
              @click="deleteGroup(group)"
              @mousedown.left.stop
              @mousedown.right.stop="$emit('headerclick', $event)"
            />
            <q-btn
              :tabindex="-1"
              dense
              flat
              size="sm"
              :icon="isExpanded ? 'sym_r_expand_less' : 'sym_r_expand_more'"
              @click="handleExpandButtonClick"
              @mousedown.left.stop
              @mousedown.right.stop="$emit('headerclick', $event)"
            >
              <q-tooltip :delay="1000">
                <template v-if="isExpanded">
                  {{ $t("inquiryPositionsPage.offerPositionGroup.collapse") }}
                  <kbd class="kbd-dark q-ml-xs">&larr;</kbd>
                </template>
                <template v-else>
                  <div>
                    {{ $t("inquiryPositionsPage.offerPositionGroup.expand") }}
                    <kbd class="kbd-dark q-ml-xs">&rarr;</kbd>
                  </div>
                  <div class="q-mt-xs">
                    ({{ $t("inquiryPositionsPage.offerPositionGroup.shift") }} +
                    {{ $t("inquiryPositionsPage.offerPositionGroup.click") }})
                    {{
                      $t(
                        "inquiryPositionsPage.offerPositionGroup.expandAdditionally"
                      )
                    }}
                    <span class="q-ml-xs">
                      <kbd class="kbd-dark">⇧</kbd> +
                      <kbd class="kbd-dark">&rarr;</kbd>
                    </span>
                  </div>
                </template>
              </q-tooltip>
            </q-btn>
          </div>
        </div>
      </q-item-section>
    </q-item>
    <div
      v-if="isExpanded"
      :class="[
        'positions-table-item-dropdown',
        'column',
        'full-width',
        { selected: isSelected },
      ]"
      @keydown.stop.a
      @keydown.stop.f
      @keydown.stop.enter
      @mousedown.left.stop
      @mousedown.right.stop="$emit('headerclick', $event)"
    >
      <q-separator />
      <div class="column no-wrap full-width">
        <div
          class="column no-wrap full-width"
          ref="offerPositionsListEl"
          :key="updateOnSortKey"
        >
          <div
            v-for="(position, idx) in group.offerPositions"
            :key="position.id"
          >
            <offer-position
              :ref="
              (el) => {
                offerPositionRefs[idx] = el as typeof OfferPosition | null;
              }
            "
              :position="position"
              :group="group"
              :disabled="disabled"
              :order="idx"
            />
            <q-separator />
          </div>
        </div>
        <template
          v-if="organization?.usePrices && group.offerPositions.length > 0"
        >
          <total-price-row :positions="group.offerPositions" />
          <q-separator />
        </template>
        <new-offer-position
          ref="newOfferPositionRef"
          v-if="!disabled"
          :group="group"
          @offer-position-added="selectNewlyAddedOfferPosition"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import NewOfferPosition from "@/components/InquiryPositionsPage/OfferPosition/NewOfferPosition.vue";
import OfferPosition from "@/components/InquiryPositionsPage/OfferPosition/OfferPosition.vue";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import type { OfferPositionGroup } from "@/types/offerPositionGroup";
import { useSortable } from "@vueuse/integrations/useSortable";
import { storeToRefs } from "pinia";
import { QItem, useQuasar } from "quasar";
import { computed, nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import MultiProductInput from "./MultiProductInput.vue";
import SupplierSelectButton from "./SupplierSelectButton.vue";
import ToggleOfferedButton from "./ToggleOfferedButton.vue";
import TotalPriceRow from "./TotalPriceRow.vue";

const q = useQuasar();
const { t } = useI18n();

const props = defineProps<{
  group: OfferPositionGroup;
  isSelected: boolean;
  isExpanded: boolean;
  disabled: boolean;
}>();

const emit = defineEmits<{
  activate: [];
  headerclick: [event: MouseEvent];
  inputclick: [];
  setOffered: [isOffered: boolean];
  setCompleted: [isCompleted: boolean];
  expand: [];
  expandOnly: [];
  collapse: [];
}>();

defineExpose({
  expand,
  collapse,
  activate,
  expandOnly,
  focusFirstOfferPosition,
});

const {
  updateOfferPositionGroup,
  deleteOfferPositionGroup,
  moveOfferPosition,
} = useCurrentOfferPositionGroupsStore();

const { organization } = storeToRefs(useCurrentOrganizationStore());
const { canEditInquiryPositions } = storeToRefs(useCurrentInquiryStore());

const isActive = ref(false);

const canHeaderBeEdited = computed(
  () => props.group.isManuallyCreated && canEditInquiryPositions.value
);

const headerClass = computed(() => {
  return {
    "positions-table-item-header": true,
    offered: props.group.isOffered,
    completed: props.group.isOffered && props.group.isCompleted,
    selected: props.isSelected,
    active: isActive.value,
  };
});

const offerPositionRefs = ref<Record<number, typeof OfferPosition | null>>({});
const newOfferPositionRef = ref<typeof NewOfferPosition | null>(null);

const isCompletedCheckbox = computed({
  get: () => props.group.isCompleted && props.group.isOffered,
  set: (value: boolean) => {
    if (props.disabled || props.group.temporaryId) return;
    emit("setCompleted", value);
  },
});

const headerEl = ref<typeof QItem | null>(null);
const productInputEl = ref<typeof MultiProductInput | null>(null);
function focusHeader() {
  headerEl.value?.$el?.focus();
}

function activate() {
  productInputEl.value?.$el?.focus();
}

function deactivate() {
  productInputEl.value?.forceBlur();
}

watch(
  () => props.isSelected,
  (isSelected) => {
    if (!isSelected) {
      deactivate();
      referenceIdEl.value?.blur();
      shortTextEl.value?.blur();
    }
  }
);

function expand() {
  emit("expand");
}

function expandOnly() {
  emit("expandOnly");
}

function collapse() {
  emit("collapse");
  focusHeader();
}

function handleExpandButtonClick(event: Event) {
  if (props.isExpanded) {
    collapse();
  } else {
    if ((event as MouseEvent).shiftKey) {
      expand();
    } else {
      expandOnly();
    }
  }
}

function deleteGroup(group: OfferPositionGroup) {
  if (group.temporaryId) return;

  if (group.offerPositions.length === 0) {
    deleteOfferPositionGroup(group.id);
    return;
  }

  q.dialog({
    title: t("inquiryPositionsPage.offerPositionGroup.confirmDelete.title"),
    message: t("inquiryPositionsPage.offerPositionGroup.confirmDelete.message"),
    ok: {
      flat: true,
      dense: true,
      label: t("Delete"),
      color: "negative",
    },
    cancel: {
      flat: true,
      dense: true,
    },
  }).onOk(() => deleteOfferPositionGroup(group.id));
}

async function selectNewlyAddedOfferPosition() {
  await nextTick();
  const lastOfferPosition =
    offerPositionRefs.value[props.group.offerPositions.length - 1];
  lastOfferPosition?.select();
}

const referenceIdEl = ref<HTMLDivElement | null>(null);
const shortTextEl = ref<HTMLDivElement | null>(null);
function focusReferenceIdEl() {
  referenceIdEl.value?.focus();
}
function focusShortTextEl() {
  shortTextEl.value?.focus();
}

async function focusFirstOfferPosition() {
  await nextTick();
  if (props.group.offerPositions.length === 0) {
    await focusNewOfferPosition();
    return;
  } else {
    await focusFirstAddedPosition();
  }
}

async function focusNewOfferPosition() {
  const el = newOfferPositionRef.value;
  if (!el) throw new Error("New offer position not found");
  el.focus();
}

async function focusFirstAddedPosition() {
  const el = offerPositionRefs.value[0];
  if (!el) throw new Error("First offer position not found");
  el.focus();
}

function handleReferenceIdElBlur() {
  let newValue: string | null = referenceIdEl.value?.innerText || null;
  if (!newValue || !newValue.length) newValue = null; // backend doesn't accept empty strings
  updateOfferPositionGroup(props.group.id, { boqReferenceId: newValue });
}
function handleShortTextElBlur() {
  let newValue: string | null = shortTextEl.value?.innerText || null;
  if (!newValue || !newValue.length) newValue = null; // backend doesn't accept empty strings
  updateOfferPositionGroup(props.group.id, { boqTextShort: newValue });
}

const offerPositionsListEl = ref<HTMLDivElement | null>(null);

// We increase this key after each sort to trigger a re-render of the positions list DOM, which
// has been manually manipulated by the sortable.
const updateOnSortKey = ref(0);

watch(
  () => [props.group.offerPositions, offerPositionsListEl.value],
  () => {
    if (!offerPositionsListEl.value) {
      return;
    }
    if (!props.group.offerPositions) {
      console.error("Offer positions not loaded - cannot initialize sortable");
      return;
    }
    useSortable(offerPositionsListEl.value, props.group.offerPositions, {
      handle: ".offer-position-drag-handle-reference",
      animation: 150,
      onUpdate: async (e: any) => {
        if (!props.group.offerPositions) {
          console.error(
            "Offer positions not loaded - cannot save offer position order"
          );
          return;
        }
        await moveOfferPosition(props.group.id, e.oldIndex, e.newIndex);
        updateOnSortKey.value++;
      },
    });
  },
  { immediate: true }
);
</script>

<style lang="scss">
$reference-id-min-width: 60px;
$reference-id-max-width: 200px;

.positions-table-item {
  &-header {
    font-size: smaller;
    color: $neutral-6;
    background-color: $neutral-1;
    border-bottom: 1px solid $separator-color;

    > .q-focus-helper {
      // managing focus highlighting manually
      display: none;
    }

    &.offered {
      color: $neutral-10;
      background-color: $white;
    }

    &.completed {
      color: $neutral-10;
      background-color: $neutral-2;
    }

    &.selected {
      background-color: $primary-1;
    }

    &.selected .q-focus-helper {
      // no automatically set quasar item background on focus
      display: none !important;
    }

    &.active {
      .positions-table-item-reference-id {
        flex-shrink: 1;
      }

      .positions-table-item-short-text {
        flex-shrink: 1;
      }

      .positions-table-item-rest {
        width: 80%;
      }

      .positions-table-item-quick-input {
        width: unset;
        min-width: 20%;
        max-width: 100%;
        flex-shrink: 1;
        flex-grow: 1;
        text-overflow: ellipsis;
      }
    }
  }

  &-toggle-offered-button {
    &:hover {
      color: $neutral-9;
    }
  }

  &-reference-id {
    min-width: $reference-id-min-width;
    max-width: $reference-id-max-width;

    &:focus-within {
      max-width: unset;
    }
  }

  &-rest {
    width: 45%;
  }

  &-quick-input {
    width: 100%;
    flex-shrink: 1;
  }

  &-amount {
    text-align: right;
    min-width: 50px;
    flex-shrink: 0;
    white-space: nowrap;
  }

  &-dropdown {
    font-size: smaller;
    border-bottom: 1px solid $separator-color;
    background-color: $neutral-1;

    &.selected {
      background-color: rgba($primary-1, 0.3);
    }

    .reference-id {
      font-weight: bold;
      font-size: regular;
      min-width: $reference-id-min-width;
      max-width: $reference-id-max-width;
      margin-right: 8px !important;
    }

    .text-short {
      font-weight: bold;
      font-size: regular;
    }
  }
}

.no-ellipsis-while-editing:focus {
  display: inline-block;
  text-overflow: initial;
}

.no-ellipsis-while-editing {
  white-space: nowrap;
  overflow: hidden;
}
</style>
