<template>
  <q-list separator>
    <q-item
      v-if="inquiry?.email"
      dense
      clickable
      v-ripple
      @click.stop.prevent="downloadInquiryEmail(inboxId, inquiry.id)"
    >
      <q-item-section>
        <q-item-label class="ellipsis">
          <q-icon name="sym_r_email" class="q-mr-sm" color="neutral-8" />
          {{ inquiry.email.subject }}
        </q-item-label>
      </q-item-section>
    </q-item>
    <q-item
      class="no-decoration"
      v-for="document in inquiry?.documents"
      :key="document.id"
      clickable
      v-ripple
      dense
      @click.stop.prevent="downloadDocument(inboxId, inquiry!.id, document.id)"
    >
      <q-item-section>
        <q-item-label class="row no-wrap items-center">
          <q-icon
            :name="getDocumentTypeIcon(document.fileType)"
            class="q-mr-sm"
            color="neutral-8"
          />
          <filename-display :filename="document.filename" />
        </q-item-label>
      </q-item-section>
      <q-item-section side right class="q-ml-md" v-if="document.isBoq">
        <q-badge outline color="neutral-8" :label="$t('BOQ')" />
      </q-item-section>
      <q-item-section side right style="width: 80px">
        {{ filesize(document.filesizeKb * 1024, { locale }) }}
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script setup lang="ts">
import { downloadDocument } from "@/api/document";
import { downloadInquiryEmail } from "@/api/inquiry";
import FilenameDisplay from "@/components/FilenameDisplay.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { getDocumentTypeIcon } from "@/utils/icons";
import { filesize } from "filesize";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

const { inboxId } = useRouteParams();

const { inquiry } = storeToRefs(useCurrentInquiryStore());

const { locale } = useI18n();
</script>
