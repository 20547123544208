<template>
  <q-separator
    v-if="organization?.useProductGroupDiscounts || inquiry?.hasQuotation"
  />
  <div class="column no-wrap gap-y-md">
    <inquiry-values-display-card
      v-if="organization?.useProductGroupDiscounts"
      caption="inquiryPage.discounts.header"
    >
      <inquiry-discounts-table />
    </inquiry-values-display-card>
  </div>
</template>

<script setup lang="ts">
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { storeToRefs } from "pinia";
import InquiryValuesDisplayCard from "../InquiryValuesDisplayCard.vue";
import InquiryDiscountsTable from "./InquiryDiscountsTable.vue";

const { organization } = storeToRefs(useCurrentOrganizationStore());
const { inquiry } = storeToRefs(useCurrentInquiryStore());
</script>

<style scoped lang="scss">
.inquiry-value-card {
  border: 1px solid rgba(0, 0, 0, 0.24);
}
</style>
