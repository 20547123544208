import { clientWithoutErrorNotifications } from "@/api/client/client";

export async function createBatchProductImportJob(
  organizationId: number,
  file: File
) {
  const formData = new FormData();
  formData.append("file", file);

  const response = await clientWithoutErrorNotifications.post(
    `/organizations/${organizationId}/batch_product_import_job/`,
    formData
  );
  return response.data;
}

export async function getLastBatchProductImportJob(organizationId: number) {
  const response = await clientWithoutErrorNotifications.get(
    `/organizations/${organizationId}/batch_product_import_job/?page_size=1`
  );

  return response.data.results.length > 0 ? response.data.results[0] : null;
}
