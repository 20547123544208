<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="row justify-between items-center">
        <div class="text-xl">
          {{
            isEdit
              ? $t("supplierContactDialog.titleEdit")
              : $t("supplierContactDialog.titleAdd")
          }}
        </div>
        <q-btn flat round dense icon="sym_r_close" @click="onDialogHide" />
      </q-card-section>
      <q-card-section>
        <q-form @submit="addSupplierContact" class="column no-wrap gap-sm">
          <q-input
            autofocus
            dense
            outlined
            v-model="firstName"
            :label="$t('supplierContact.firstName')"
          />
          <q-input
            dense
            outlined
            v-model="lastName"
            :label="$t('supplierContact.lastName')"
          />
          <q-input
            dense
            outlined
            v-model="email"
            :label="$t('supplierContact.email')"
            :rules="['email']"
            @keyup.enter="handleSubmit"
          />

          <q-banner rounded class="bg-negative text-white q-mb-md" v-if="error">
            {{ $t(`supplierContactDialog.errors.${error}`) }}
            <template v-slot:action>
              <q-btn
                flat
                color="white"
                :label="$t('Dismiss')"
                @click="error = null"
              />
            </template>
          </q-banner>

          <div class="row justify-end full-width">
            <q-btn
              dense
              flat
              @click="handleSubmit"
              :disable="!email.length || isLoading"
            >
              {{
                isEdit
                  ? $t("supplierContactDialog.okButtonEdit")
                  : $t("supplierContactDialog.okButtonAdd")
              }}
            </q-btn>
          </div>
        </q-form>
      </q-card-section>
      <q-inner-loading :showing="isLoading" />
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { createSupplierContact, updateSupplierContact } from "@/api/supplier";
import type { SupplierContact } from "@/types/supplier";
import { isAxiosError } from "axios";
import { useDialogPluginComponent } from "quasar";
import { ref } from "vue";

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogHide, onDialogOK } = useDialogPluginComponent();

const props = withDefaults(
  defineProps<{
    initialEmail?: string;
    supplierId: number;
    contact?: SupplierContact;
    isEdit?: boolean;
  }>(),
  {
    isEdit: false,
  }
);

const isLoading = ref(false);
const error = ref<string | null>(null);

const firstName = ref(props.contact?.firstName ?? "");
const lastName = ref(props.contact?.lastName ?? "");
const email = ref(props.contact?.address ?? props.initialEmail ?? "");

async function handleSubmit() {
  if (props.isEdit) {
    await editSupplierContact();
  } else {
    await addSupplierContact();
  }
}

async function addSupplierContact() {
  if (!email.value) return;

  isLoading.value = true;
  error.value = null;
  let contact: SupplierContact | null = null;

  try {
    contact = await createSupplierContact(
      props.supplierId,
      {
        firstName: firstName.value,
        lastName: lastName.value,
        address: email.value,
      },
      { ignoreErrors: [400] }
    );

    if (contact) {
      onDialogOK(contact);
    }
  } catch (e) {
    if (isAxiosError(e) && e.response?.data?.reasons) {
      error.value = e.response?.data?.reasons?.[0];
    } else {
      throw e;
    }
  } finally {
    isLoading.value = false;
  }
}

async function editSupplierContact() {
  if (!email.value) return;
  if (!props.contact?.id) throw new Error("Contact ID is required");

  isLoading.value = true;
  error.value = null;
  let contact: SupplierContact | null = null;

  try {
    contact = await updateSupplierContact(
      props.supplierId,
      props.contact.id,
      {
        firstName: firstName.value,
        lastName: lastName.value,
        address: email.value,
      },
      { ignoreErrors: [400] }
    );
    if (contact) {
      onDialogOK(contact);
    }
  } catch (e) {
    if (isAxiosError(e) && e.response?.data?.reasons) {
      error.value = e.response?.data?.reasons?.[0];
    } else {
      throw e;
    }
  } finally {
    isLoading.value = false;
  }
}
</script>
