<template>
  <q-chip
    dense
    :clickable="!disable"
    :removable="!disable"
    color="neutral-1"
    :text-color="disable ? 'neutral-7' : undefined"
    @remove="!disable && $emit('remove')"
    :tabindex="tabindex"
    class="q-ml-xs cursor-pointer"
    @click.stop.prevent
    @mousedown.stop.prevent
    @touchstart.stop.prevent
    @keydown.stop.prevent
    style="outline: 1px solid var(--q-neutral-5)"
  >
    <q-avatar color="neutral-5" text-color="white">
      <q-icon name="sym_r_email" size="12px" color="white" />
    </q-avatar>
    <div class="q-mx-sm">
      <template v-if="recipient.displayName">
        <div>{{ recipient.displayName }}</div>
      </template>
      <div v-else>{{ recipient.address }}</div>
    </div>
    <hover-menu :offset="[0, 8]">
      <q-card class="q-pa-sm">
        <div class="row no-wrap items-center gap-sm">
          <div>
            <q-icon name="sym_r_person" size="md" color="neutral-5" />
          </div>
          <div class="col">
            <div v-if="recipient.displayName" class="text-sm">
              {{ recipient.displayName }}
            </div>
            <div class="text-xs text-neutral-7">
              {{ recipient.address }}
            </div>
          </div>
        </div>
        <q-btn
          v-if="recipient.supplierContactId == null && recipient.userId == null"
          dense
          size="sm"
          color="primary"
          icon="sym_r_add"
          class="q-mt-sm"
          v-close-popup
          @click="addToSupplierContacts(recipient.address)"
        >
          {{ $t("supplierRfqsPage.addToSupplierContacts") }}
        </q-btn>
      </q-card>
    </hover-menu>
  </q-chip>
</template>

<script setup lang="ts">
import HoverMenu from "@/components/HoverMenu.vue";
import SupplierContactDialog from "@/components/SupplierContactDialog.vue";
import type { SupplierContact } from "@/types/supplier";
import type { SupplierRfqRecipient } from "@/types/supplierRfq";
import { useQuasar } from "quasar";

const props = defineProps<{
  recipient: SupplierRfqRecipient;
  tabindex: number;
  supplierId: number;
  disable?: boolean;
}>();

const emit = defineEmits<{
  remove: [];
  "update-recipient": [email: string, data: SupplierContact];
}>();

const q = useQuasar();
function addToSupplierContacts(email: string) {
  q.dialog({
    component: SupplierContactDialog,
    componentProps: {
      initialEmail: email,
      supplierId: props.supplierId,
    },
  }).onOk((data) => {
    emit("update-recipient", email, data);
  });
}
</script>
