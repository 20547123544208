import type { DocumentFileType } from "@/types/document";
import icons from "@/config/icons";
import { QuotationFileFormat } from "@/types/quotation";

export function getDocumentTypeIcon(documentType: DocumentFileType) {
  if (documentType === "GAEB") {
    return icons.documentTypes.gaeb;
  }
  return icons.documentTypes.pdf;
}

export function getQuotationFileFormatIcon(
  quotationFileFormat: QuotationFileFormat | null
) {
  if (quotationFileFormat === "PDF") {
    return icons.documentTypes.pdf;
  }
  return icons.documentTypes.gaeb;
}
