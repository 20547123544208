<template>
  <AsyncMultiSelectMenu
    :findOptions="findSuppliers"
    :selectedOptions="selectedSuppliers.suppliersInAllSelectedGroups"
    :partiallySelectedOptions="selectedSuppliers.suppliersInSomeSelectedGroups"
    :searchPlaceholder="$t('supplierSelectMenu.searchPlaceholder')"
    :labelFn="(option: SupplierWithMaybeRfqId) => option.name"
    idValue="id"
    :preloadOptions="draftSuppliers"
    @select="(supplier: SupplierWithMaybeRfqId) => addSupplierRfq(supplier)"
    @unselect="(supplier: SupplierWithMaybeRfqId) => removeSupplierRfq(supplier)"
  >
    <template #option-label="{ option }">
      <supplier-label :supplier="option" />
    </template>
    <template #option-after="{ option }">
      <template v-if="option.rfqId">
        <q-space />
        <q-btn
          flat
          dense
          round
          color="neutral-5"
          size="xs"
          icon="launch"
          class="no-decoration q-ml-xs"
          style="color: var(--q-neutral-7)"
          :to="{
            name: 'inquiry-supplier-rfqs.current-supplier-rfq',
            params: {
              supplierRfqId: option.rfqId,
            },
          }"
        >
          <q-tooltip :delay="1000">
            {{ $t("supplierSelectMenu.goToSupplierRfq") }}
          </q-tooltip>
        </q-btn>
      </template>
    </template>
    <template #after>
      <q-item
        v-for="supplierRfq in alreadySentSupplierRfqs"
        :key="supplierRfq.id"
        :to="{
          name: 'inquiry-supplier-rfqs.current-supplier-rfq',
          params: {
            supplierRfqId: supplierRfq.id,
          },
        }"
        class="no-decoration"
      >
        <q-item-section>
          <div class="row items-center no-wrap text-neutral-7">
            <q-icon
              :name="icons.supplierRfqStatus[supplierRfq.status]"
              size="16px"
              class="q-mr-sm"
              color="neutral-5"
            />
            <div>
              <div>{{ supplierRfq.supplier.name }}</div>
              <div class="text-neutral-7 text-xs">
                {{
                  [supplierRfq.supplier.externalId, supplierRfq.supplier.town]
                    .filter(Boolean)
                    .join(" - ")
                }}
              </div>
            </div>
            <q-space />
            <q-btn
              dense
              flat
              round
              color="neutral-5"
              size="xs"
              icon="launch"
              class="no-decoration q-ml-xs"
              style="color: var(--q-neutral-7)"
              :to="{
                name: 'inquiry-supplier-rfqs.current-supplier-rfq',
                params: {
                  supplierRfqId: supplierRfq.id,
                },
              }"
            >
              <q-tooltip :delay="1000">
                {{ $t("supplierSelectMenu.goToSupplierRfq") }}
              </q-tooltip>
            </q-btn>
          </div>
          <q-tooltip :delay="1000">
            {{ $t("supplierSelectMenu.alreadySentTooltip") }}
          </q-tooltip>
        </q-item-section>
      </q-item>
    </template>
  </AsyncMultiSelectMenu>
</template>

<script setup lang="ts">
import { findSuppliers as apiFindSuppliers } from "@/api/supplier";
import AsyncMultiSelectMenu from "@/components/SelectMenus/AsyncMultiSelectMenu.vue";
import SupplierLabel from "@/components/SupplierLabel.vue";
import { useSupplierRfq } from "@/composables/positionsTable/useSupplierRfq";
import { useRouteParams } from "@/composables/useRouteParams";
import icons from "@/config/icons";
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import type { Supplier } from "@/types/supplier";
import { storeToRefs } from "pinia";

type SupplierWithMaybeRfqId = Supplier & { rfqId?: number };

const {
  selectedSuppliers,
  addSupplierRfq,
  removeSupplierRfq,
  alreadySentSupplierRfqs,
} = useSupplierRfq();

const { draftSuppliers, draftSupplierRfqsBySupplierId } = storeToRefs(
  useCurrentOfferPositionGroupsStore()
);

const { organizationId } = useRouteParams();

async function findSuppliers(searchText: string) {
  if (!organizationId.value) return [];

  const suppliers: SupplierWithMaybeRfqId[] = await apiFindSuppliers(
    organizationId.value,
    searchText
  );
  for (const supplier of suppliers) {
    supplier.rfqId = draftSupplierRfqsBySupplierId.value.get(supplier.id)?.id;
  }
  return suppliers;
}
</script>
