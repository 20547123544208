<template>
  <div class="slim-page">
    <settings-page-header section="organization" page="batchProductImportJob" />
    <file-dropzone
      :disabled="isUploadDisabled"
      @click="fileInputEl?.click()"
      class="cursor-pointer"
      @drop="(files: File[]) => saveBatchProductImportJob(files[0])"
    >
      <div
        style="
          height: 250px;
          border: 1px solid #ccc;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <div class="column items-center" style="gap: 8px">
          <q-icon name="sym_r_cloud_upload" size="5rem" color="neutral-6" />
          <div :class="{ 'text-neutral-7': isImportInProgress }">
            {{
              file
                ? file.name
                : $t(
                    "settings.organization.batchProductImportJob.dropFilesHelpText"
                  )
            }}
          </div>
        </div>
      </div>
      <input
        :disabled="isUploadDisabled"
        type="file"
        style="display: none"
        ref="fileInputEl"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        @change="(event: any) => saveBatchProductImportJob(event.target.files[0])"
      />
      <q-inner-loading :showing="isUploading" />
    </file-dropzone>
    <div class="q-mt-md full-width row justify-end">
      <a
        :href="`/assets/import-templates/kinisto-schema-products.xlsx`"
        target="_blank"
        class="text-primary"
      >
        {{
          $t("settings.organization.batchProductImportJob.downloadExampleFile")
        }}
      </a>
    </div>
    <q-card v-if="lastBatchProductImportJob || isUploading" class="q-mt-xl">
      <q-card-section class="q-mt-md">
        <q-chip
          v-if="lastBatchProductImportJob"
          square
          icon="sym_r_file_present"
          :label="lastBatchProductImportJob.originalFilename"
          :color="
            lastBatchProductImportJob.status === 'SUCCESS'
              ? 'positive-2'
              : FAILED_STATUSES.includes(lastBatchProductImportJob.status)
              ? 'negative-1'
              : 'neutral-2'
          "
        />
        {{
          isUploading
            ? $t("settings.organization.batchProductImportJob.statuses.CREATED")
            : $t(
                `settings.organization.batchProductImportJob.statuses.${
                  lastBatchProductImportJob!.status
                }`
              )
        }}
        <q-circular-progress
          indeterminate
          class="q-mx-sm"
          v-if="isImportInProgress"
        />
      </q-card-section>
      <q-card-section
        v-if="
          lastBatchProductImportJob &&
          FAILED_STATUSES.includes(lastBatchProductImportJob.status)
        "
        class="bg-negative-1"
      >
        <q-icon class="q-mr-sm" name="sym_r_error" color="negative" />
        <span v-if="lastBatchProductImportJob.status === 'INPUT_ERROR'">
          {{
            $t(
              `settings.organization.batchProductImportJob.errors.${lastBatchProductImportJob.errorCode}`,
              lastBatchProductImportJob.errorParams! as keyable
            )
          }}
        </span>
        <span v-else>
          {{ $t("settings.organization.batchProductImportJob.failedError") }}
        </span>
      </q-card-section>
    </q-card>
  </div>
</template>

<script setup lang="ts">
import {
  createBatchProductImportJob,
  getLastBatchProductImportJob,
} from "@/api/batchProductImportJob";
import FileDropzone from "@/components/FileDropzone.vue";
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import { usePolling } from "@/composables/usePolling";
import { useRouteParams } from "@/composables/useRouteParams";
import type { BatchProductImportJob, keyable } from "@/types/organization";
import { executeInNewTrace } from "@/utils/sentry";
import { useQuasar } from "quasar";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const FAILED_STATUSES = ["INPUT_ERROR", "FAILED"];
const IN_PROGRESS_STATUSES = ["PROCESSING", "CREATED"];

const q = useQuasar();
const { t } = useI18n();

const { organizationId } = useRouteParams();

const file = ref<File | null>(null);

const isUploading = ref(false);
const lastBatchProductImportJob = ref<BatchProductImportJob | null>(null);
const fileInputEl = ref<HTMLInputElement | null>(null);

const isImportInProgress = computed(() =>
  lastBatchProductImportJob.value
    ? IN_PROGRESS_STATUSES.includes(lastBatchProductImportJob.value.status)
    : false
);
const isUploadDisabled = computed(
  () => isUploading.value || isImportInProgress.value
);

onMounted(async () => {
  // If the last job is still in progress, we want to show it immediately
  const lastJob = await getLastBatchProductImportJob(organizationId.value);
  if (lastJob?.status && IN_PROGRESS_STATUSES.includes(lastJob.status)) {
    lastBatchProductImportJob.value = lastJob;
  }
});

const POLLING_INTERVAL = 1000;

const { start } = usePolling(
  () => executeInNewTrace(silentUpdateStatus, "poll-batch-product-import-job"),
  () => true,
  POLLING_INTERVAL
);
onMounted(start);

async function silentUpdateStatus() {
  if (
    lastBatchProductImportJob.value &&
    IN_PROGRESS_STATUSES.includes(lastBatchProductImportJob.value?.status)
  ) {
    lastBatchProductImportJob.value = await getLastBatchProductImportJob(
      organizationId.value
    );
  }
}

async function saveBatchProductImportJob(file: File) {
  isUploading.value = true;
  lastBatchProductImportJob.value = null;

  try {
    lastBatchProductImportJob.value = await createBatchProductImportJob(
      organizationId.value,
      file
    );
  } catch (e: any) {
    const errorMessage = Object.entries(e.response.data)
      .map(([, value]) => {
        const errorsList = value as string[];
        return errorsList.map(t).join(" ");
      })
      .join("\n\n");
    q.notify({
      message: errorMessage,
      color: "negative",
    });
  } finally {
    isUploading.value = false;
  }
}
</script>
